// ** MUI Imports
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tooltip from "@mui/material/Tooltip";

const FooterContent = () => {
  // ** Var
  const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const footerStyle = {
    display: mobile ? "block" : "flex",
    justifyContent: mobile ? "center" : "space-between",
    marginRight: mobile ? "0rem" : "4.5rem",
    textAlign: mobile ? "center" : undefined,
  };
  return (
    <div className={"footer"} style={{ margin: "0px 2px", ...footerStyle }}>
      <div className="copyright-container">
        <span className="copyright">
          <small>
            Copyright &copy; {`${new Date().getFullYear()}`}{" "}
            <span className={"font-weight-semibold"}>SalesPark</span> - Feito com{" "}
            <Tooltip placement="top" title="Feito com amor e muito café">
              <>❤️ ➕ ☕ </>
            </Tooltip>{" "}
            - versão 2.1.242
          </small>
        </span>
      </div>
      <div>
        <Link className="text-gray" href="https://salespark.io/redirect/?u=general-terms-of-use" target="_blank">
          <small>Termos e Políticas</small>
        </Link>
      </div>
    </div>
  );

  // return (
  //   <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
  //     <Typography sx={{ mr: 2 }}>
  //       {`© ${new Date().getFullYear()}, Made with `}
  //       <Box component='span' sx={{ color: 'error.main' }}>
  //         ❤️
  //       </Box>
  //       {` by `}
  //       <Link target='_blank' href='https://salespark.io/'>
  //         SalesPark
  //       </Link>
  //     </Typography>
  //     {hidden ? null : (
  //       <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', '& :not(:last-child)': { mr: 4 } }}>
  //         <Link target='_blank' href='https://themeforest.net/licenses/standard'>
  //           License
  //         </Link>
  //         <Link target='_blank' href='https://1.envato.market/pixinvent_portfolio'>
  //           More Themes
  //         </Link>
  //         <Link
  //           target='_blank'
  //           href='https://pixinvent.com/demo/materialize-mui-react-nextjs-admin-template/documentation'
  //         >
  //           Documentation
  //         </Link>
  //         <Link target='_blank' href='https://pixinvent.ticksy.com/'>
  //           Support
  //         </Link>
  //       </Box>
  //     )}
  //   </Box>
  // )
};

export default FooterContent;
